<template>
  <main download>
    <h2>DATA</h2>
    <ul class="list">
      <li>
        <img src="/img/data-1.png" alt="">
        <div class="cont">
          <h3>FIT Brochure</h3>
          <a @click="isBrochurePopup = true" target="_blank" class="download">Product Guide</a>
          <a @click="isBrochurePopup = true" target="_blank" class="download">Design Guide</a>
        </div>
      </li>
      <li>
        <img src="/img/data-2.png" alt="">
        <div class="cont">
          <h3>FIT Brand Film</h3>
          <a @click="youtubeID = 'ZpgknHnsVsU?si=xTwPOjGjNSFKQhAF'" class="out-link">Watch Video (Eng)</a>
          <a @click="youtubeID = 'YYVHcU9Beeg?si=1EjJyGbaFCydbN6Q'" class="out-link">Watch Video (Kor)</a>
        </div>
      </li>
    </ul>
    <popup brochure-popup v-if="isBrochurePopup">
      <div class="panel">
        <div class="wrap">
          <a @click="isBrochurePopup = false" class="close"></a>
          <h4>브로셔 요청하기</h4>
          <p>현재는 메일로 신청하신 분에게만 브로셔를 발송 중입니다. 아래 양식을 참고하셔서 메일로 요청부탁드립니다.</p>
          <hr>
          <p>이메일: <a href="mailto:fit_platform@samoo.com">fit_platform@samoo.com</a></p>
          <p>이름 / 소속 / 직책 / 규모(1,000평 빌딩) 등 기재 사항 포함하여 작성해 주시고, 원하시는 브로셔 종류 말씀해주시면 빠른 시일 내에 답변드리겠습니다.</p>
          <a href="mailto:fit_platform@samoo.com" class="btn-send">메일 보내기</a>
        </div>
      </div>
    </popup>
    <popup video-popup v-if="youtubeID">
      <div class="panel">
        <a @click="youtubeID = ''" class="close"></a>
        <iframe width="560" height="315" :src="`https://www.youtube.com/embed/${youtubeID}?showinfo=0&rel=0`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </popup>
  </main>
</template>
<script>

import Popup from '@/views/common/Popup.vue';

export default {
  name: 'download',
  components: { Popup },
  metaInfo() {
    return {
      title: 'FIT Data',
      meta: [
        { vmid: 'title', content: 'FIT Data', },
        { vmid: 'description', content: 'FIT Platform 의 대한 자세하고 명확한 내용을 브로셔와 동영상으로 전달해 드립니다.', },
        { vmid: 'image', content: `${process.env.VUE_APP_HOST}/img/og.png`, },
        { vmid: 'path', content: this.$route.path },
      ],
    }
  },
  data() {
    return {
      isBrochurePopup: false,
      youtubeID: '',
    };
  },
};
</script>

<style lang="less">
@import "~@/less/prod.less";

[download] { .pt(60);
  h2 { .p(80,0); .fs(40,1); .tc; }
  .list { .m(0, 20);
    > li { .pb(80);
      img { .block; .wf; .max-w(100%); .br(4); }
      .cont { .flex-center; flex-direction: column; }
      h3 { .mt(40); .fs(32,1); }
      a { .block; .rel; .mt(24); .p(12,16); .w(208); .medium; .-a(#2e2e2e); .br(3); .tr-a(0.3s);
        &:after { .cnt; .abs; .rt(16,50%); .t-yc; .wh(24); .no-repeat; }
        @{hover} { .bgc(#2e2e2e); .c(#fff); }
        &.download:after { .contain('/img/common/ico-download.svg'); }
        &.out-link:after { .contain('/img/common/ico-outlink.svg'); }
        &.download { @{hover}:after { .contain('/img/common/ico-download-w.svg'); }}
        &.out-link { @{hover}:after { .contain('/img/common/ico-outlink-w.svg'); }}
      }
      a + a { .mt(16); }
    }
    > li + li { .pb(128); }
  }
}
[brochure-popup] {
  .panel { .wf; .fvh; background-color: #fff;
    .wrap { .p(100,36); }
    h4 { .fs(26,40); .bold; }
    h4 + p { .fs(18,30); .medium; .mt(32); }
    hr { .block; .m(32,0); .h(1); background-color: #DADADA; }
    hr + p { .fs(14,20); .medium; }
    hr + p + p { .fs(12,20); .c(#666); .mt(8); }
    a.btn-send { .block; .tc; .fs(16,1); .m(56,auto,0); .p(12, 0); .w(184); .-a(#1e1e1e); .br(3); .tr-a(0.3s);
      @{hover} { .bgc(#1e1e1e); .c(#fff); }
    }
  }
}

[video-popup] {
  .panel { .wh(335,188); background-color: #fff; .mh-c;
    iframe { .block; .f; }
  }
}

@media (min-width: @screen-tp-min) {
  [download] {
    .list { .w(432); .mh-c; }
  }
  [brochure-popup] {
    .panel {
      .wrap { .ph(0); .w(412); .mh-c; }
    }
  }
  [video-popup] {
    .panel { .wh(638,358); }
  }
}
@media (min-width: @screen-tl-min) {
  [download] {
    h2 { .p(112,0); .fs(64,1); }
    .list { .m(0, 80); .w(auto);
      > li { .grid(2); .pb(120);
        img { .br-l(4); }
        .cont { .bgc(#EFEFEF); .br-r(4); }
        h3 { .mt(0); .fs(40,1); }
        h3 + a{ .mt(32); }
      }
      > li + li { .mt(0); }
    }
  }
  [brochure-popup] {
    .panel { .wh(768,592); .mh-c;
      .wrap { .p(100,112); .w(auto); }
      h4 { .fs(32,40); }
      h4 + p { .fs(24,40); }
      hr + p { .fs(18,20); }
      hr + p + p { .fs(16,24); .mt(12); }
    }
  }
  [video-popup] {
    .panel { .wh(768,432); }
  }
}
@media (min-width: @screen-ds-min) {
  [download] { .pt(80); }
  [video-popup] {
    .panel { .wh(1024,576); }
  }
}
@media (min-width: @screen-dl-min) {
  [download] { .w(1600); .mh-c; }
  [video-popup] {
    .panel { .wh(1280,720); }
  }
}
</style>
